import React, { useState,useLayoutEffect } from "react";

import{BsPencilFill} from 'react-icons/bs';

import axios from "axios";

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Navbar from '../../Navbar/Navbar'
import '../AdminStyle.css'

const Sections = () => {

  //fonctions de fermeture et de d'ouverture de modal

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //state pour recuperer les libelles
  const [listeLibelle, setListeLibelle] = useState([]);

  //states de modification des libelles

  const [id_libelle, setId_libelle] = useState();
  const [libelle_sect, setLibelle_sect] = useState();

  //Fontion de recuperation des libelles des sections

  const get_libbelle_section = async () => {
    await axios
      .get("https://api.evoluticgroupe.com/evolutic/libelle")
      .then((res) => {
        setListeLibelle(res.data.data);
        console.log(res);
      })
      .catch((error) => console.error("error.data"));
  };

  //fonction de modification des libelles

  const updateLibelle = async (id_libelle) => {

    const recuperation_info = {
      lib_sect:libelle_sect
    };
    
    await axios
      .put("https://api.evoluticgroupe.com/evolutic/libelle/" + id_libelle, recuperation_info)
      .then((res) => {
        get_libbelle_section();
        handleClose()
      })
      .catch((error) => console.log(error.data));
  };

  const get_libelle = async (id_libelle, libelle_sect) => {
    setId_libelle(id_libelle)
    setLibelle_sect(libelle_sect)
    handleShow()
  };

  const renderTooltip = (props) => ( 
    <Tooltip id="button-tooltip" {...props}>
      modifier le texte
    </Tooltip>
  );

  useLayoutEffect(() => {
    get_libbelle_section();
  }, []);


  return (
    <div className="section">

        <Navbar/>

        <div className="sous_conteneur_service">

        <Table striped>
          <thead>
            <tr>

              <th style={{ fontSize: '1.2em' }}>Texte des sections</th>
              <th style={{ fontSize: '1.2em', textAlign: 'center' }}>Action</th>

            </tr>
          </thead>
          <tbody>
            {
              listeLibelle.map((item) => (
                <tr>

                  <td>{item.lib_sect}</td>
                
                  <td style={{ textAlign: 'center' }}>

                    <OverlayTrigger
                        delay={{ show: 100, hide: 100 }}
                        overlay={renderTooltip}
                      >

                        <BsPencilFill 
                          onClick={ ()=> get_libelle(item.id_lib,item.lib_sect) } 
                          style={{color:'blue',marginBottom:'30%'}}
                          />

                    </OverlayTrigger>

                  </td>
                  
                </tr>
              ))
            }

          </tbody>
        </Table>

        {/* Modal de modification */}


        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modification du texte</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Texte</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="texte"
                  autoFocus
                  value={libelle_sect}
                  onChange={(e) => setLibelle_sect(e.target.value)}
                />
              </Form.Group>
              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <Button variant="primary" onClick={() => updateLibelle(id_libelle)}>
              Modifier
            </Button>
          </Modal.Footer>
        </Modal>

        </div>

    </div>
  )
}

export default Sections