import React from 'react';
import {FaUserAlt} from 'react-icons/fa'
import {NavLink} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import LogoEvolutic from '../Asset/ImagesFront/LogoEvolutic.png';
import Navbar from 'react-bootstrap/Navbar';
import './StyleSidenav.css'


const navbar = () => {

  const active = ({isActive}) => {

    return( 
        isActive ? {
                backgroundColor :"green"
        } : {

            undefined
        }
    )
}


  return (
  <div className='ma_navigation'>

      <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{width:'100%'}}>
      <Container>
        <Navbar.Brand ><img src={LogoEvolutic} alt='logo'/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" style={{width:"70%"}}>
            
            <NavLink to='/Acceuil' className="lien" style={active}>Acceuil</NavLink>
            <NavLink to='/Service'  className="lien" style={active}>Services</NavLink>
            <NavLink to='/Realisation'  className="lien" style={active}>Réalisations</NavLink>
            <NavLink to='/Contact' className="lien" style={active}>Contacts</NavLink> 
            <NavLink to='/Sections' className="lien" style={active}>Sections</NavLink> 

          </Nav>
          <Nav className='deconnection'>

            <NavLink to='/' className="linkDeconnection">
              <FaUserAlt/>
              |Deconnection
            </NavLink> 

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

     </div>
  )
}

export default navbar