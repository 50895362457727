import React from 'react'
import Navbar from '../../Navbar/Navbar'
import Header from './Header'
import Partenaire from './Partenaire'

const Acceuil = () => {
  return (
    <div className='acceuil'>

        <div className="barDeNavigation">
          <Navbar/>
        </div>

        <div className='contenuAcceuil'>
          <Header/> 
          <Partenaire/> 
        </div> 


    </div>
  )
}

export default Acceuil