import React, { useState, useEffect,useLayoutEffect } from "react";
import axios from "axios";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Navbar from '../../Navbar/Navbar'
import '../AdminStyle.css'
function Realisation() {

  //Fonction de fermeture et d'ouverture du modal de realisation 

  const [ajouterRealisation, setAjouterRealisation] = useState(false);
  const fermerModalRealisation = () => setAjouterRealisation(false);
  const ouvrirModalRealisation = () => setAjouterRealisation(true);

  //Fonction de fermeture et d'ouverture du modal de modification

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  ///Fonction de fermeture et d'ouverture du modal de suppression

  const [shows, setShows] = useState(false);
  const fermer = () => setShows(false);
  const ouvrir = () => setShows(true);

  //Les states d'ajout

  const [idService, setIdService] = useState()     /* state pour recuperer l'id des services*/
  const [titreRealisation, setTitreRealisation] = useState();
  const [imageRealisation, setImageRealisation] = useState();

  // Le state afficheur liste realisation
  const [listeRealisation, setListeRealisation] = useState([]);

//Les states de modification de realisation

  const [id_realisation, setId_realisation] = useState();
  const [image_Modification_Realisation, setImage_Modification_Realisation] = useState();
  const [titre_Modification_Realisation, setTitre_Modification_Realisation] = useState();

  // State afficheur d'id service
  const [listeService, setListeService] = useState([]);

  const ajouterReal = async () => {

    const formData = new FormData();
    formData.append("id_serv", idService);
    formData.append("titre_real", titreRealisation);
    formData.append("img_real", imageRealisation);
  
    await axios
      .post("https://api.evoluticgroupe.com/evolutic/realisation", formData)
      .then((res) => {
        getRealisation();
        fermerModalRealisation();
        console.log(res);
      })
      .catch((error) => console.log(error.data));
  };

  //Fonction de recuperation des realisations

  const getRealisation = async () => {
    await axios
      .get("https://api.evoluticgroupe.com/evolutic/realisation")
      .then((res) => {
        setListeRealisation(res.data.data);
        console.log(res);
      })
      .catch((error) => console.error("error.data"));
  };

  //Fonction de modification des realisations

  const updateRealisation = async (id_realisation) => {

    const formData = new FormData();
    formData.append("img_real", image_Modification_Realisation);
    formData.append("titre_real", titre_Modification_Realisation);

    await axios
      .put("https://api.evoluticgroupe.com/evolutic/realisation/" + id_realisation, formData)
      .then((res) => {
        getRealisation();
        handleClose()
      })
      .catch((error) => console.log(error.data));
  };

  //Fonction de suppression de realisation

  const deleteRealisation = async (key) => {

    await axios
      .delete("https://api.evoluticgroupe.com/evolutic/realisation/" + id_realisation)
      .then((res) => {
        console.log(res)
        getRealisation();
        fermer()
      })
      .catch((error) => console.error("error.data"));
  };

  //Recuperation des id de service

  const getService = async () => {
    await axios
      .get("https://api.evoluticgroupe.com/evolutic/service")
      .then((res) => {
        setListeService(res.data.data);
        console.log(res);
      })
      .catch((error) => console.error("error.data"));
  };

  const getRealisationId = async (id_realisation) => {
    setId_realisation(id_realisation)
    ouvrir()
  };


  const getRealisationInfo = async (id_realisation,image_Modification_Realisation, titre_Modification_Realisation) => {
    setId_realisation(id_realisation)
    setImage_Modification_Realisation(image_Modification_Realisation)
    setTitre_Modification_Realisation(titre_Modification_Realisation)
    handleShow()
  };

  useLayoutEffect(() => {
    getService();
    getRealisation();
  }, []);

  return (
    <div className='realisation'>

      <div className="barDeNavigation">
          <Navbar />
      </div>
        
    

      <div className='ContainerPort'>

      <Table striped>
          <thead>
            <tr>
              <th style={{ fontSize: '1.2em' }}>Images de realisations</th>
              <th style={{ fontSize: '1.2em' }}>Titres de realisations</th>
              <th colSpan={2} style={{ fontSize: '1.2em', textAlign: 'center' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              listeRealisation.map((item) => (
                <tr>

                  <td>

                      <img src={
                        require(`../../../../../../back-end/src/Asset/ImagesRealisation/${item.img_real}`)
                        } 
                        alt=" " 
                        style={{ width: "40px", height: "30px" }}
                      />
                
                  </td>
                  <td>{item.titre_real}</td>
                  
                  <td style={{ textAlign: 'center' }}>
                    <Button variant="primary" onClick={() =>
                      getRealisationInfo(item.id_real, item.img_real, item.titre_real)
                    }
                    >
                      Modifier
                    </Button>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Button variant="danger" onClick={() => getRealisationId(item.id_real)}>
                      Supprimer
                    </Button>
                  </td>
                </tr>
              ))
            }

          </tbody>
        </Table>

                {/* Suppression */}

        <Modal show={shows} onHide={fermer} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Suppression de reamlisation</Modal.Title>
          </Modal.Header>
          <Modal.Body>voulez-vous vraiment Supprimer la realisation?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={fermer}>
              Fermer
            </Button>
            <Button variant="danger" onClick={() => deleteRealisation(id_realisation)}>
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Modal de modification de realisation */}

                
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modification des realisations</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>

              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Image  realisation</Form.Label>
                <Form.Control
                  type="file"
                  name="icon_serv"
                  autoFocus
                  onChange={(e) => setImage_Modification_Realisation(e.target.files[0])}
                />
                
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Titre realisation</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Titre realisation"
                  autoFocus
                  value={titre_Modification_Realisation}
                  onChange={(e) => setTitre_Modification_Realisation(e.target.value)}
                />
              </Form.Group>
              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <Button variant="primary" onClick={() => updateRealisation(id_realisation)}>
              Modifier
            </Button>
          </Modal.Footer>
        </Modal>

            {/* bouton et modal d'ajout de realisation */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginBottom: '4%'
          }}
        >

          <Button variant="primary" onClick={ouvrirModalRealisation}>
            Ajouter
          </Button>
          <Modal show={ajouterRealisation} onHide={fermerModalRealisation}>
            <Modal.Header closeButton>
              <Modal.Title>Ajouter une image de realisation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                method="POST"
                action='/Header'
                encType="multipart/form-data"
              >

                <Form.Select
                  aria-label="Default select example"
                  value={idService}
                  onChange={(e) => setIdService(e.target.value)}
                >
                  <option>Les services</option>
                {
                  listeService.map((item) => (
              
                  <option value={item.id_serv}>{item.titre_serv}</option>
                
                  ))
                }
                </Form.Select>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Titre realisation</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Titre realisation"
                    autoFocus
                    value={titreRealisation}
                    onChange={(e) => setTitreRealisation(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Image realisation</Form.Label>
                  <Form.Control
                    type="file"
                    autoFocus
                    name="img_real"
                    onChange={(e) => setImageRealisation(e.target.files[0])}
                  />
                </Form.Group>

              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={fermerModalRealisation}>
                Fermer
              </Button>
              <Button variant="primary" onClick={ajouterReal}>
                Ajouter
              </Button>
            </Modal.Footer>
          </Modal>

        </div>
      </div>
    </div>
  )
}

export default Realisation