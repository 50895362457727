import React, { useState,useLayoutEffect } from "react";

import axios from "axios";

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Navbar from '../../Navbar/Navbar'
import '../AdminStyle.css'

function Service() {

  // const [ajoutServ, setAjoutServ] = useState(false);
  // const fermerModalService = () => setAjoutServ(false);
  // const ouvrirModalService = () => setAjoutServ(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [shows, setShows] = useState(false);
  // const fermer = () => setShows(false);
  // const ouvrir = () => setShows(true);


  //Ajout de service
  // const [icone, setIcone] = useState();
  // const [titre, setTitre] = useState();
  // const [texte, setTexte] = useState();

  const [listeService, setListeService] = useState([]);

  const [id_service, setId_service] = useState();
  const [icone_service, setIcone_service] = useState();
  const [titre_service, setTitre_service] = useState();
  const [texte_service, setTexte_service] = useState();


  // const ajouterService = async () => {

  //   const formData = new FormData();
  //   formData.append("icon_serv", icone);
  //   formData.append("titre_serv", titre);
  //   formData.append("text_serv", texte);


  //   await axios
  //     .post("https://api.evoluticgroupe.com/evolutic/service", formData)
  //     .then((res) => {
  //       getService();
  //       console.log(res);
  //     })
  //     .catch((error) => console.log(error.data));
  // };

  const getService = async () => {
    await axios
      .get("https://api.evoluticgroupe.com/evolutic/service")
      .then((res) => {
        setListeService(res.data.data);
        console.log(res);
      })
      .catch((error) => console.error("error.data"));
  };

  const updateService = async (id_service) => {
    //  console.log("bonjour")

    const formData = new FormData();
    formData.append("icon_serv", icone_service);
    formData.append("titre_serv", titre_service);
    formData.append("text_serv", texte_service);

  
    //console.log(collecter_param);
    await axios
      .put("https://api.evoluticgroupe.com/evolutic/service/" + id_service, formData)
      .then((res) => {
        getService();
        handleClose()
      })
      .catch((error) => console.log(error.data));
  };

  // const deleteService = async (key) => {

  //   await axios
  //     .delete("https://api.evoluticgroupe.com/evolutic/service/" + id_service)
  //     .then((res) => {
  //       console.log(res)
  //       getService();
  //       fermer()
  //     })
  //     .catch((error) => console.error("error.data"));
  // };

  // const getSerciceId = async (id_service) => {
  //   setId_service(id_service)
  //   ouvrir()
  // };


  const getServiceInfo = async (id_service, icone_service, titre_service, texte_service) => {
    setId_service(id_service)
    setIcone_service(icone_service)
    setTitre_service(titre_service)
    setTexte_service(texte_service)
    handleShow()
  };


  useLayoutEffect(() => {
    getService();
  }, []);


  return (
    <div className='service'>

      
        <Navbar/>
      
    
      <div className='sousContainerserv'>
        <Table striped>
          <thead>
            <tr>
              <th style={{ fontSize: '1.2em' }}>Icons Services</th>
              <th style={{ fontSize: '1.2em' }}>Titres Services</th>
              <th style={{ fontSize: '1.2em' }}>Textes Services</th>
              <th colSpan={2} style={{ fontSize: '1.2em', textAlign: 'center' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              listeService.map((item) => (
                <tr>

                  <td>

                      <img src={
                        require(`../../../../../../back-end/src/Asset/ImagesServ/${item.icon_serv}`)
                        } 
                        alt=" " 
                        style={{ width: "40px", height: "30px" }}
                      />
                
                  </td>
                  <td>{item.titre_serv}</td>
                  <td>{item.text_serv}</td>
                  <td style={{ textAlign: 'center' }}>
                    <Button variant="primary" onClick={() =>
                      getServiceInfo(item.id_serv, item.icon_serv, item.titre_serv, item.text_serv)
                    }
                    >
                      Modifier
                    </Button>
                  </td>
                  {/* <td style={{ textAlign: 'center' }}>
                    <Button variant="danger" onClick={() => getSerciceId(item.id_serv)}>
                      Supprimer
                    </Button>
                  </td> */}
                </tr>
              ))
            }

          </tbody>
        </Table>
        {/* Modification */}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modification des Services</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>icone service</Form.Label>
                <Form.Control
                  type="file"
                  name="icon_serv"
                  autoFocus
                  onChange={(e) => setIcone_service(e.target.files[0])}
                />
                
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Titre service</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Titre service"
                  autoFocus
                  value={titre_service}
                  onChange={(e) => setTitre_service(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Texte service</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Texte"
                  value={texte_service}
                  onChange={(e) => setTexte_service(e.target.value)}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            <Button variant="primary" onClick={() => updateService(id_service)}>
              Modifier
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Suppression */}

        {/* <Modal show={shows} onHide={fermer} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Suppression de service</Modal.Title>
          </Modal.Header>
          <Modal.Body>voulez-vous vraiment Supprimer?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={fermer}>
              Fermer
            </Button>
            <Button variant="danger" onClick={() => deleteService(id_service)}>
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal> */}

        {/* Ajout */}

        {/* <div className='boutonAjouterCont'>
          <Button variant="primary" onClick={ouvrirModalService}>
            Ajouter
          </Button>

          <Modal show={ajoutServ} onHide={fermerModalService}>
            <Modal.Header closeButton>
              <Modal.Title>Ajout  d'icone</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>icone service</Form.Label>
                  <Form.Control
                    type="file"
                    name="icon_serv"
                    autoFocus
                    onChange={(e) => setIcone(e.target.files[0])}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Titre service</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Titre service"
                    autoFocus
                    value={titre}
                    onChange={(e) => setTitre(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Texte service</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Texte"
                    autoFocus
                    value={texte}
                    onChange={(e) => setTexte(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={fermerModalService}>
                Fermer
              </Button>
              <Button variant="primary" onClick={ajouterService}>
                Ajouter
              </Button>
            </Modal.Footer>
          </Modal>
        </div> */}

      </div>

    </div>
  )
}

export default Service