import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Contact from './components/Administration/Contact/Contact';
import Acceuil from './components/Administration/Acceuil/Acceuil';
import Realisation from './components/Administration/Realisation/Realisation';
import Service from './components/Administration/Service/Service';
import Sections from './components/Administration/Sections/Sections';
import Connection from './components/Administration/Connection/Connection';

import Navbar from './components/Navbar/Navbar';



function App() {
  return (
    <div >
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Connection/>}/>
          <Route path='/Acceuil' element={<Acceuil/>}/>
          <Route path='/Navbar' element={<Navbar/>}/>
          <Route path='/Contact' element={<Contact/>}/>
          <Route path='/Realisation' element={<Realisation/>}/>
          <Route path='/Service' element={<Service/>}/>
          <Route path='/Sections' element={<Sections/>}/>
          
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
