
import React, { useState, useEffect,useLayoutEffect } from "react";
import axios from "axios";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../AdminStyle.css'

function Header() {

  // const [ajoutImage, setAjoutImage] = useState(false);
  // const fermerModalImage = () => setAjoutImage(false);
  // const ouvrirModalImage = () => setAjoutImage(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [texteAcceuil, setTexteAcceuil] = useState();
  // const [imageAcceuil, setImageAcceuil] = useState();

  const [listeTexteAcceuil, setListeTexteAcceuil] = useState([]);
  const [id_texteAcceuil, setId_texteAcceuil] = useState();
  const [imageModifAcceuil, setImageModifAcceuil] = useState();
  const [texteModif, setTexteModif] = useState();

  // const ajouterTexteAcceuil = async () => {


  //   const formData = new FormData();
  //   formData.append("img_head", imageAcceuil);
  //   formData.append("text_head", texteAcceuil);

  //   await axios
  //     .post("https://api.evoluticgroupe.com/evolutic/header",formData)
  //     console.log(formData)
  //     .then((res) => {
  //       getTexte();
  //       fermerModalImage();
  //       console.log(res);
  //     })
  //     .catch((error) => console.log(error.data));
  // };

  const getTexte = async () => {
    await axios
      .get("https://api.evoluticgroupe.com/evolutic/header")
      .then((res) => {
        setListeTexteAcceuil(res.data.data);
        console.log(res);
      })
      .catch((error) => console.error("error.data"));
  };
  
  const updateAcceuil = async (id_texteAcceuil) => {
  
    const formData = new FormData();
    formData.append("img_head", imageModifAcceuil);
    formData.append("text_head", texteModif);
    //console.log(collecter_texte);
    await axios
      .put("https://api.evoluticgroupe.com/evolutic/header/" + id_texteAcceuil, formData)
      .then((res) => {
        getTexte();
        handleClose()
      })
      .catch((error) => console.log(error.data));
  };


  // const getAcceuilId = async (id_texteAcceuil) => {
  //   setId_texteAcceuil(id_texteAcceuil)
  //   handleShow()
  // };

  
  const getAcceuilInfo = async (id_texteAcceuil, imageModifAcceuil, texteModif) => {
    setId_texteAcceuil(id_texteAcceuil);
    setImageModifAcceuil(imageModifAcceuil);
    setTexteModif(texteModif)
    handleShow()
  };

  useLayoutEffect(() => {
    getTexte();
  }, []);

  return (
    <div className='container_header'>

      <h3 style={{ color: 'blue',marginTop:'3%' }}>Image et texte du header  </h3>

      <Table striped>
        <thead>
          <tr>
            <th style={{ fontSize: '1.2em' }}>Image Acceuil</th>
            <th style={{ fontSize: '1.2em',textAlign:'center' }}>Texte Acceuil</th>
            <th style={{ fontSize: '1.2em', textAlign: 'center' }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            listeTexteAcceuil.map((item) => (
              <tr>
                <td> 
                  <img src={
                    require(`../../../../../../back-end/src/Asset/Images/${item.img_head}`)
                    } 
                    alt=" " 
                    style={{ width: "50px", height: "30px" }}
                  />
                </td>
                <td style={{ textAlign:'center' }}>{item.text_head}</td>
                <td style={{ textAlign: 'center' }}>
                  <Button variant="primary" onClick={
                    () => {getAcceuilInfo(item.id_head, item.img_head, item.text_head)}
                  }
                  >
                    Modifier
                  </Button>
                </td>

              </tr>
            ))
          }

        </tbody>
      </Table>
      {/* <div>
        
        <Button variant="primary" onClick={ouvrirModalImage}>
          Ajouter
        </Button>
        <Modal show={ajoutImage} onHide={fermerModalImage}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter une image et un texte</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              method="POST"
              action='/Header'
              encType="multipart/form-data"
            >
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  autoFocus
                  name="img_head"
                  onChange={(e) => setImageAcceuil(e.target.files[0])}
                

                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Texte acceuil</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Texte"
                  autoFocus
                  value={texteAcceuil}
                  onChange={(e) => setTexteAcceuil(e.target.value)}
                  required
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={fermerModalImage}>
              Fermer
            </Button>
            <Button variant="primary" onClick={ajouterTexteAcceuil}>
              Ajouter
            </Button>
          </Modal.Footer>
        </Modal>

      </div> */}

      {/* Modal de Modification */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modification d'image et du texte d'acceuil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            method="POST"
            action='/Header'
            encType="multipart/form-data"
          >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                autoFocus
                name="img_head"
                onChange={(e) => setImageModifAcceuil(e.target.files[0])}
                required

              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Texte acceuil</Form.Label>
              <Form.Control
                type="text"   
                placeholder="Texte"
                autoFocus
                value={texteModif}
                onChange={(e) => setTexteModif(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={() => updateAcceuil(id_texteAcceuil)}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>



    </div>
  )
}

export default Header