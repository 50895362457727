import React, { useState, useEffect ,useLayoutEffect} from "react";
import axios from "axios";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../AdminStyle.css'

function Partenaire() {

  //Fonction de fermeture et d'ouverture du modal d'ajout 

  const [ajoutImage, setAjoutImage] = useState(false);
  const fermerModalImage = () => setAjoutImage(false);
  const ouvrirModalImage = () => setAjoutImage(true);

  //Fonction de fermeture et d'ouverture du modal de modification

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

//Fonction de suppression de partenaire
  const [showA, setShowA] = useState(false);
  const fermerAbon = () => setShowA(false);
  const ouvrirAbon = () => setShowA(true);


  //Les states d'ajout

  const [imagePart, setImagePart] = useState();

  // Les states de modification
  const [listeImage, setListeImage] = useState([]);
  const [id_partModif, setId_partModif] = useState();
  const [imageModifPart, setImageModifPart] = useState();



//Fonction d'ajout

 const ajouterImage = async () => {

    const formData = new FormData();
    formData.append("img_part", imagePart);

    await axios
      .post("https://api.evoluticgroupe.com/evolutic/partenaire",formData)
      .then((res) => {
        getImage();
        fermerModalImage();
        console.log(res);
      })
      .catch((error) => console.log(error.data));
  };

  const getImage = async () => {
    await axios
      .get("https://api.evoluticgroupe.com/evolutic/partenaire")
      .then((res) => {
        setListeImage(res.data.data);
        console.log(res);
      })
      .catch((error) => console.error("error.data"));
  };

// Fonction de modification

  const updateImage = async (id_part) => {
  
    const formData = new FormData();
    formData.append("img_part", imageModifPart);
  
    await axios
      .put("https://api.evoluticgroupe.com/evolutic/partenaire/" + id_partModif, formData)
      .then((res) => {
        getImage();
        handleClose()
      })
      .catch((error) => console.log(error.data));
  };

//Fonction de suppression

  const deletePartenaire = async (key) => {

    await axios
      .delete("https://api.evoluticgroupe.com/evolutic/partenaire/" + id_partModif)
      .then((res) => {
        console.log(res)
        getImage();
        fermerAbon()
      })
      .catch((error) => console.error("error.data"));
  };

  const getPartenaireId = async (id_partModif) => {
    setId_partModif(id_partModif)
    ouvrirAbon()
  };

  const getPartenaireInfo = async (id_partModif, imageModifPart) => {
    setId_partModif(id_partModif);
    setImageModifPart(imageModifPart);
    handleShow()
  };

  useLayoutEffect(() => {
    getImage();
  }, []);

  return (
    <div className="container_partenaire">
      <h3 style={{ color: 'blue' }}>Mes partenaires</h3> 
      <Table striped>
              <thead>
                <tr>
                  <th style={{ fontSize: '1.2em' }}>Image header</th>
                  <th style={{ fontSize: '1.2em', textAlign: 'center' }} colSpan='2'>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  listeImage.map((item) => (
                    <tr>
                      <td> 
                        <img src={
                          require(`../../../../../../back-end/src/Asset/ImagesPart/${item.img_part}`)
                          } 
                          alt=" " 
                          style={{ width: "50px", height: "30px" }}
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <Button variant="primary" onClick={
                          () => {getPartenaireInfo(item.id_part, item.img_part)}
                        }
                        >
                          Modifier
                        </Button>
                      </td>

                      <td style={{ textAlign: 'center' }}>
                        <Button variant="danger" onClick={() => getPartenaireId(item.id_part)}>
                          Supprimer
                        </Button>
                      </td>

                    </tr>
                  ))
                }

              </tbody>
            </Table>


      {/* Modal d'ajout d'image */}

      <div 
        style={{
          width:'100%',
          display:'flex',
          flexDirection:'row',
          justifyContent:'flex-end',
          marginBottom:'4%'
        }}
      >
        
        <Button variant="primary" onClick={ouvrirModalImage}>
          Ajouter
        </Button>
        <Modal show={ajoutImage} onHide={fermerModalImage}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter une image </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              method="POST"
              action='/Header'
              encType="multipart/form-data"
            >
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  autoFocus
                  name="img_part"
                  onChange={(e) => setImagePart(e.target.files[0])}
                

                />
              </Form.Group>
              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={fermerModalImage}>
              Fermer
            </Button>
            <Button variant="primary" onClick={ajouterImage}>
              Ajouter
            </Button>
          </Modal.Footer>
        </Modal>

      </div>

            {/* Modal de Modification */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier l'image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            method="POST"
            action='/Header'
            encType="multipart/form-data"
          >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                autoFocus
                name="img_part"
                onChange={(e) => setImageModifPart(e.target.files[0])}
                required

              />
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={() => updateImage(id_partModif)}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>

       {/* Model suppression de partenaire */}

       <Modal show={showA} onHide={fermerAbon} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Suppression de partenaire</Modal.Title>
          </Modal.Header>
          <Modal.Body>voulez-vous vraiment Supprimer?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={fermerAbon}>
              Fermer
            </Button>
            <Button variant="danger" onClick={() => deletePartenaire(id_partModif)}>
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>

    </div>
  )
}

export default Partenaire

