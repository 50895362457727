import React, { useState } from 'react'
import '../AdminStyle.css'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from "axios";

const Compte = () => {

  const [message, setMessage] = useState("Bienvenue à evolutic")
  const [em, setEm] = useState()
  const [mp, setMp] = useState()

  const toLogin = async (em, mp) => {
    const entete = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }

    const data_login = {
      "username": em,
      "password": mp
    }
    console.log(data_login)
    await axios.post('https://api.evoluticgroupe.com/evolutic/login', data_login, { headers: entete })
      .then(success => {

        setMessage("Connexion Réussie, Vous serez redirigez bientôt")
        window.location = '/Acceuil';

      })
      .catch(error => {
        setMessage(error.response.data.message)
      });
  };

  return (
    <div className="connection">

      <Form className='frm forme'>
        <div className='message_bienvenu'>{message}</div>
        <Form.Group className="mb-3" controlId="formGroupEmail">
          <Form.Label className='label'>Nom d'utilisateur</Form.Label>
          <Form.Control type="text" placeholder="nom" className='champ_de_texte'
            value={em}
            onChange={(e) => setEm(e.target.value)}
            autoFocus
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword">
          <Form.Label className='label'>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" className='champ_de_texte'
            value={mp}
            onChange={(e) => setMp(e.target.value)}
          />
        </Form.Group>
        <div>
          <Button variant="success" className='bouton' onClick={() => toLogin(em, mp)}>se connecter</Button>{' '}
          <Button variant="danger" className='bouton'>Annuler</Button>
        </div>

      </Form>
    </div>
  )
}
export default Compte;