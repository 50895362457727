
import React, { useState, useLayoutEffect } from "react";

import axios from "axios";

import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



//import { NavLink } from 'react-router-dom'
import Navbar from '../../Navbar/Navbar'
import '../AdminStyle.css'

export default function Contact() {

  //Fonctions de gestions des  modales-----> section ajout contact
 
  const [shows, setShows] = useState(false);
  const fermer = () => setShows(false);
  const ouvrir = () => setShows(true);

  const [ajout, setAjout] = useState(false);
  const fermerModalContact = () => setAjout(false);
  const ouvrirModalContact = () => setAjout(true);

  //Les states d'Ajout de contact
  const [nom, setNom] = useState();
  const [email, setEmail] = useState();
  const [sujet, setSujet] = useState();
  const [message, setMessage] = useState();

  const [listeContact, setListeContact] = useState([]);
  const [id_cont, setId_cont] = useState();
  

  //Fonctions de gestions des  modales-----> section ajout Abonnement

  const [ajoutAbon, setAjoutAbon] = useState(false);
  const fermerModalAbon = () => setAjoutAbon(false);
  const ouvrirModalAbon = () => setAjoutAbon(true);

  const [showA, setShowA] = useState(false);
  const fermerAbon = () => setShowA(false);
  const ouvrirAbon = () => setShowA(true);

  ///Les states d'ajout d'abonne

  const [emailAbon, setEmailAbon] = useState();

  const [listeAbonne, setListeAbonne] = useState([]);
  const [id_abonne, setId_abonne] = useState();

  //Fonction d'ajout d'abonne

  const ajouterAbonner = async () => {

    const collecter_abonne = {

      email_abon: emailAbon

    };

    await axios
      .post("https://api.evoluticgroupe.com/evolutic/abonnement", collecter_abonne)
      .then((res) => {
        getAbonne();
        console.log(res);
      })
      .catch((error) => console.log(error.data));
  };

  const getAbonne = async () => {
    await axios
      .get("https://api.evoluticgroupe.com/evolutic/abonnement")
      .then((res) => {
        setListeAbonne(res.data.data);
        console.log(res);
      })
      .catch((error) => console.error("error.data"));
  };

  const deleteAbon = async (key) => {

    await axios
      .delete("https://api.evoluticgroupe.com/evolutic/abonnement/" + id_abonne)
      .then((res) => {
        console.log(res)
        getAbonne();
        fermerAbon()
      })
      .catch((error) => console.error("error.data"));
  };

  const getAbonId = async (id_abonne) => {
    setId_abonne(id_abonne)
    ouvrirAbon()
  };

  // const getAbonInfo = async (id_abonne, modifEmailAbon) => {
  //   setId_cont(id_abonne)
  //   setEmailAbon(modifEmailAbon)
  //   ouvrirModalAbon()
  // };

  // Fonction d'ajout contact

  const ajouterContact = async () => {

    const collecter_contact = {
      nom,
      email_form: email,
      sujet,
      message
    };

    await axios
      .post("https://api.evoluticgroupe.com/evolutic/contact", collecter_contact)
      .then((res) => {
        getContact();
        console.log(res);
      })
      .catch((error) => console.log(error.data));
  };

  const getContact = async () => {
    await axios
      .get("https://api.evoluticgroupe.com/evolutic/contact")
      .then((res) => {
        setListeContact(res.data.data);
        console.log(res);
      })
      .catch((error) => console.error("error.data"));
  };

  
  const deleteContact = async (key) => {

    await axios
      .delete("https://api.evoluticgroupe.com/evolutic/contact/" + id_cont)
      .then((res) => {
        console.log(res)
        getContact();
        fermer()
      })
      .catch((error) => console.error("error.data"));
  };



  const getContactId = async (id_cont) => {
    setId_cont(id_cont)
    ouvrir()
  };


  useLayoutEffect(() => {
    getContact();
    getAbonne();
  }, []);

  return (
    <div className='contact'>

      
        <Navbar/>
      

      <div className='ContainerCont'>
        <h3 style={{ color: 'blue', marginTop: '26px' }}>Mes contacts</h3>
        <Table striped  style={{width:'60%'}}>
          <thead>
            <tr>

              <th style={{ fontSize: '1em' }}>Noms</th>
              <th style={{ fontSize: '1em' }}>Emails</th>
              <th style={{ fontSize: '1em' }}>Sujets</th>
              <th style={{ fontSize: '1em' }}>Messages</th>
              <th  style={{ fontSize: '1em', textAlign: 'center' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              listeContact.map((item) => (
                <tr>
                  <td>{item.nom}</td>
                  <td>{item.email_form}</td>
                  <td>{item.sujet}</td>
                  <td>{item.message}</td>

                  <td style={{ textAlign: 'center' }}>
                    <Button variant="danger" onClick={() => getContactId(item.id_contact)}>
                      Supprimer
                    </Button>
                  </td>

                </tr>
              ))
            }

          </tbody>
        </Table>
        

        {/* Modal de suppression */}

        <Modal show={shows} onHide={fermer} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Suppression des informations</Modal.Title>
          </Modal.Header>
          <Modal.Body>voulez-vous vraiment Supprimer?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={fermer}>
              Fermer
            </Button>
            <Button variant="danger" onClick={() => deleteContact(id_cont)}>
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Ajout de conct */}
  
          <div className="boutonAjouterCont">
            <Button variant="primary" onClick={ouvrirModalContact}>
              Ajouter
            </Button>
          </div>
          <Modal show={ajout} onHide={fermerModalContact}>
            <Modal.Header closeButton>
              <Modal.Title>Ajouter vos informations</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Nom</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="nom "
                    autoFocus
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>sujet</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sujet"
                    autoFocus
                    value={sujet}
                    onChange={(e) => setSujet(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea" rows={3}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={fermerModalContact}>
                Fermer
              </Button>
              <Button variant="primary" onClick={ajouterContact}>
                Ajouter
              </Button>
            </Modal.Footer>
          </Modal>
        

        {/* Abonnement */}

        <h3 style={{ color: 'blue' }}>Abonnés</h3>

        <Table striped>
          <thead>
            <tr>

              <th style={{ fontSize: '1.3em' }}>Email abonné</th>
              <th style={{ fontSize: '1.3em', textAlign: 'center' }}>Action</th>

            </tr>
          </thead>
          <tbody>
            {
              listeAbonne.map((item) => (
                <tr>

                  <td>{item.email_abon}</td>

                  <td style={{ textAlign: 'center' }}>
                    <Button variant="danger" onClick={() => getAbonId(item.id_abon)}>
                      Supprimer
                    </Button>
                  </td>

                </tr>
              ))
            }

          </tbody>
        </Table>

        {/* Model ajout abonne */}

        <div className="boutonAjoutAbon">
          <Button variant="primary" onClick={ ouvrirModalAbon }
            >
             Ajouter
          </Button>
        </div>

        <Modal show={ajoutAbon} onHide={fermerModalAbon}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter vos abonnés</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>


              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  autoFocus
                  value={emailAbon}
                  onChange={(e) => setEmailAbon(e.target.value)}
                />
              </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={fermerModalAbon}>
              Fermer
            </Button>
            <Button variant="primary" onClick={ajouterAbonner}>
              Ajouter
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Model suppression abonne */}

        <Modal show={showA} onHide={fermerAbon} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Suppression d'abooné</Modal.Title>
          </Modal.Header>
          <Modal.Body>voulez-vous vraiment Supprimer?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={fermerAbon}>
              Fermer
            </Button>
            <Button variant="danger" onClick={() => deleteAbon(id_abonne)}>
              Supprimer
            </Button>
          </Modal.Footer>
        </Modal>

      </div>

    </div>
  )
}

